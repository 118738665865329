import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faMedium, faGithub } from '@fortawesome/free-brands-svg-icons'
import styled from '@emotion/styled'

const icons = {
  linkedIn: faLinkedin,
  medium: faMedium,
  github: faGithub
}
const ClickableIcon = styled.a`
  color: #222;
  font-size: 24px;
  margin-right: .5em;
  -webkit-transition: margin 0.2s ease-out;
  -moz-transition: margin 0.2s ease-out;
  -o-transition: margin 0.2s ease-out;
  :hover {
    cursor:pointer;
    margin-top: 5px;
  }
`

export default ({ icon, href }) => <ClickableIcon target="_blank" href={href}>
  <FontAwesomeIcon color="#222" icon={icons[icon]} />
</ClickableIcon>