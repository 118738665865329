import React from 'react';
import styled from '@emotion/styled'
import { ClickableIcon, SectionTitle, Section, SectionContent, Item, ItemTitle, ItemSubtitle, ItemDescription } from './components'
import picture from './bwheadshot.jpg'

const Header = styled('h1')`
  padding-top: 1em;
  color: #222;
  font-family: monospace;
`

const Inline = styled.div`
  display: inline-flex;
`

const Circle = styled.div`
  height: 140px;
  width: 140px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 1em;
  margin-right: 2em;
`

const Grow = styled.div`
  flex-grow: 1;
`

function App() {
  return (
    <>
      <Inline>
        <Circle>
          <img src={picture} />
        </Circle>
        <Grow>
          <Header>
            Blake Wight
    </Header>
          <div>
            <ClickableIcon href='http://medium.com/@bwighthunter' icon='medium' />
            <ClickableIcon href='http://linkedin.com/in/blakewight/' icon='linkedIn' />
            <ClickableIcon href='http://github.com/bwighthunter' icon='github' />
          </div>
        </Grow>
      </Inline>
      <Section>
        <SectionTitle>
          Experience
        </SectionTitle>
        <SectionContent>
          <Item><ItemTitle>Workfront</ItemTitle>
            <ItemSubtitle>November 2018 - Present</ItemSubtitle>
            <ul>
              <ItemDescription>Architected and wrote a calendar application for customers to manage their work</ItemDescription>
              <ItemDescription>Fixed bugs and wrote new features for Workfront’s first new product in 18 years</ItemDescription>
              <ItemDescription>Negotiated with product managers to simplify the work required to deliver value to customers by half</ItemDescription>
            </ul>
          </Item>
          <Item><ItemTitle>Freelance</ItemTitle>
            <ItemSubtitle>September 2017 - November 2017</ItemSubtitle>
            <ul>
              <ItemDescription>Added features for a Ruby on Rails web store</ItemDescription>
            </ul>
          </Item>
          <Item><ItemTitle>Jarvis Ventures</ItemTitle>
            <ItemSubtitle>September 2017 - September 2017</ItemSubtitle>
            <ul>
              <ItemDescription>Went under 2 weeks after hiring me</ItemDescription>
            </ul>
          </Item>
          <Item><ItemTitle>Pariveda Solutions</ItemTitle>
            <ItemSubtitle>June 2016 - September 2017</ItemSubtitle>
            <ul>
              <ItemDescription>Implement data capture, ingestion, processing pipeline for Big-Data solutions</ItemDescription>
              <ItemDescription>Architect Big-Data Solutions with managed services in the cloud</ItemDescription>
              <ItemDescription>Implemented faceted filter/search on web front-end</ItemDescription>
            </ul>
          </Item>
        </SectionContent>
      </Section>

    </>
  );
}

export default App;
